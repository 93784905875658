<template>
  <div class="mt-12">
    <div
      v-for="questionItem in questionnaire.questions"
      :key="questionItem.indexNumber"
      class="mb-3"
    >
      <v-subheader
        class="pl-0"
        style="font-size: 15px; font-weight: 500; color: black; height: auto"
      >
        {{ questionItem.indexNumber }}. {{ questionItem.question }}
      </v-subheader>
      <div v-if="questionItem.type === 'OPEN'" class="mt-3">
        {{ questionItem.answer ? questionItem.answer : "Нет ответа" }}
      </div>
      <v-slider
        v-if="questionItem.type === 'SCALE'"
        class="v-slider-labels--top mt-3"
        style="max-width: 400px"
        :tick-labels="tickLabels"
        tick-size="4"
        hide-details
        step="1"
        ticks="always"
        :max="10"
        :min="1"
        v-model="questionItem.answer"
        readonly
      />
      <v-radio-group
        v-if="questionItem.type === 'YES_NO'"
        class="mt-0 --disabled"
        hide-details
        row
        readonly
        v-model="questionItem.answer"
      >
        <v-radio label="Да" value="Да"></v-radio>
        <v-radio label="Нет" value="Нет"></v-radio>
        <v-radio
          label="Затрудняюсь ответить"
          value="Затрудняюсь ответить"
        ></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const questionnaire = computed(() => store.getters.getReportQuestionnaire);

    const tickLabels = [...Array(10)].map((e, i) => (i + 1).toString());

    return {
      questionnaire,
      tickLabels,
    };
  },
};
</script>
